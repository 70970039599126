
import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'

import { required, max } from 'vee-validate/dist/rules'

// Register it globally
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('required', {
  ...required,
  message: '{_field_}不能为空'
})
// 长度校验
extend('max', {
  ...max,
  params: ['length'],
  message: '{_field_}长度不能超过{length}个字符'
})
// 手机校验
extend('phone', {
  validate (value) {
    return /^1[3456789]\d{9}$/.test(value) ? true : '手机号格式不正确'
  }
})
// 身份证
extend('idcard', {
  validate (value) {
    return /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/.test(value) ? true : '身份证格式不正确'
  }
})
// 经纬度
extend('lonlat', {
  validate (value) {
    let lngReg = /^(\\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/
    let latReg = /^(\\-|\+)?([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/
    let [lng, lat] = value.replace('，', ',').split(',')

    return lngReg.test(lng) && latReg.test(lat) ? true : '经度，纬度（逗号拼接，小数点不能超过15位）'
  }
})
