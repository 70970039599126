// 1 微信端 2 app
export const ENV = 1

// v1接口
export const PS_URL = '/ps'
// 工地接口地址
export const CONS_URL = process.env.VUE_APP_CONS_URL

export const FILE_UPLOAD = PS_URL + '/v1/file/upload' // 上传地址

// 高德服务key
export const AMAP_SERVE_KEY = '10c725f41de10938404e131f73ace3d6'
