import Vue from 'vue'
import App from './App.vue'
import 'babel-polyfill'
import router from './router'
import store from './store'
import './plugins/vant.js'
import './common/css/index.scss'
import './common/iconfont/iconfont'
import './configs/veeConfig'
import './directives/index'
import echarts from './common/js/myEcharts'

import APIS from './api/index'
import { removeUserInfo, removeToken, getToken } from '@/common/js/cache'

import VConsole from 'vconsole'
// eslint-disable-next-line no-unused-vars
var c = new VConsole()

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

// 获取url上的参数
function getUrlQuery () {
  let params = location.search.substr(1).split('&')
  let query = {}
  params.map(item => {
    let [key, val] = item.split('=')
    query[key] = val
  })
  return query
}

// 没有token时获取用户信息
function getUserInfo () {
  getWxUserInfo({
    data: JSON.stringify({ code: urlQuery['code'] }),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => {
    if (res.success) {
      store.dispatch('saveLoginInfo', res.data)
      init()
    }
    init()
  })
}

const urlQuery = getUrlQuery()

const { check, getWxUserInfo } = APIS

const init = () => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
if (getToken()) {
  check().then(res => {
    if (!res.success) {
      removeUserInfo()
      removeToken()

      getUserInfo()
    } else {
      init()
    }
  }).catch(e => {
    init()
  })
} else {
  getUserInfo()
}
