import API_CONFIG from './apiConfig'
import { request } from '@/common/js/ajax'
let APIS = {}

for (let method in API_CONFIG) {
  let methodApis = API_CONFIG[method]
  for (let key in methodApis) {
    APIS[key] = (obj) => request({
      url: methodApis[key],
      method,
      ...obj
    })
  }
}

export default APIS
