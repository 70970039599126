/**
 *
 * echarts 配置
 *
 */

// 加载echarts，注意引入文件的路径
import echarts from 'echarts/lib/echarts'

// 再引入你需要使用的图表类型，标题，提示信息等
import 'echarts/lib/chart/line'

import 'echarts/lib/component/tooltip'

export default echarts
