import Vue from 'vue'
import Vuex from 'vuex'
import { getUserInfo, getToken, saveToken, saveUserInfo, removeUserInfo, removeToken } from '@/common/js/cache'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getToken(),
    userInfo: getUserInfo() || {},
    addressInfo: {} // 位置信息
  },
  getters: {
    user: state => state.userInfo.user || {}
    // functionTreeList: state => state.userInfo.functionTreeList,
    // functionList: state => state.userInfo.functionList,
    // group: state => state.userInfo.group || {},
    // deptment: state => state.userInfo.deptment || {}
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_PHONE: (state, phone) => {
      state.userInfo.phone = phone
      saveUserInfo(state.userInfo)
    },
    SET_USERNAME: (state, userName) => {
      state.userInfo.userName = userName
      saveUserInfo(state.userInfo)
    },
    SET_HEADURL: (state, headUrl) => {
      state.userInfo.headUrl = headUrl
      saveUserInfo(state.userInfo)
    },
    SET_ADDRESS_INFO: (state, addressInfo) => {
      state.addressInfo = addressInfo
    }
  },
  actions: {
    saveLoginInfo ({ commit }, userInfo) {
      console.log(userInfo)
      commit('SET_USERINFO', userInfo)
      commit('SET_TOKEN', userInfo.accessToken)
      // 设置默认图片
      if (!userInfo.headUrl) {
        userInfo.headUrl = '/avatar.png'
      }

      saveToken(userInfo.accessToken)
      saveUserInfo(userInfo)
    },
    removeLoginInfo ({ commit }) {
      commit('SET_USERINFO', {})
      commit('SET_TOKEN', '')

      removeUserInfo()
      removeToken()
    }
  }
})
