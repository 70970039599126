import Vue from 'vue'

import {
  Button,
  Uploader,
  Circle,
  Icon,
  Field,
  Toast,
  List,
  Cell,
  Lazyload,
  PullRefresh,
  ImagePreview,
  Tabs,
  Tab,
  Radio,
  RadioGroup,
  Sticky,
  Search,
  Overlay,
  Dialog,
  SwipeCell
} from 'vant'

Vue.use(Button)
  .use(Uploader)
  .use(Circle)
  .use(Icon)
  .use(Field)
  .use(Toast)
  .use(List)
  .use(Cell)
  .use(Lazyload, { loading: '/lazy_img.png' })
  .use(PullRefresh)
  .use(ImagePreview)
  .use(Tabs)
  .use(Tab)
  .use(Radio)
  .use(RadioGroup)
  .use(Sticky)
  .use(Search)
  .use(Overlay)
  .use(Dialog)
  .use(SwipeCell)

Vue.prototype.$imagePreview = ImagePreview
Vue.prototype.$dialog = Dialog
