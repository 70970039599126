import { PS_URL, CONS_URL } from '../configs/config'
// 高德服务
const AMAP_URL = 'https://restapi.amap.com/v3/'

const SECURITY = PS_URL + '/v1/security/'
// 我要爆料相关
const DISCLOSE = PS_URL + '/disclose/'
// 互动咨询相关
const CONSULT = PS_URL + '/consult/'
// 每日一题相关
const SUBJECT = PS_URL + '/subject/'
// 预警预告相关
const ALARM = PS_URL + '/alarm/'
// 城管窗口相关
const CGWINDOW = PS_URL + '/cg-window/'
// 城管动态相关
const CGNEWS = PS_URL + '/news/'
// 积分相关
const CREDIT = PS_URL + '/credit/'
// 奖品相关
const AWARD = PS_URL + '/award/'
// 个人信息相关
const PERSON = PS_URL + '/person/'
// 地点收藏相关
const POI_COLLECT = PS_URL + '/poi/collect/'
// 微信接口
const WX = PS_URL + '/weixin/'
// 工地相关
const CONSTRUCTION = CONS_URL + '/construction/monitor/app/'
// 政策法规相关
const POLICE = PS_URL + '/policy/'
// 常见问题
const PROBLEM = PS_URL + '/question/'

export default {
  get: {
    // 微信接口
    getWxSignature: WX + 'js-signature', // 获取微信签名
    // 高德服务
    searchAddressByKey: AMAP_URL + 'assistant/inputtips', // 输入提示
    lnglatToAddress: AMAP_URL + 'geocode/regeo', // 逆地理编码
    searchPlaceAround: AMAP_URL + 'place/around', // 周边搜索

    // 我要爆料相关
    getHistoryDisclose: DISCLOSE + 'his-discloses', // 查询历史爆料信息
    getHandledDisclose: DISCLOSE + 'his-handled-discloses', // 查询已处理历史爆料信息
    getUnhandleDisclose: DISCLOSE + 'his-unhandle-discloses', // 查询未处理历史爆料信息
    getDiscloseDetail: DISCLOSE + 'disclose', // 查询爆料信息详情

    // 互动咨询相关
    getConsults: CONSULT + 'consults', // 互动咨询分页查询

    // 每日一题相关
    getDailySubject: SUBJECT + 'daily-subject', // 获取今日题目

    // 预警预告相关
    getAlarmList: ALARM + 'alarms', // 分页查询预警预告
    getAlarmDetail: ALARM + 'alarm', // 分页查询预警预告

    // 城管窗口相关
    getCgWindowList: CGWINDOW + 'windows', // 模糊查询城管窗口

    // 城管动态相关
    getCgNewDetail: CGNEWS + 'news', // 查询城管动态详情
    getCgNewsList: CGNEWS + 'news-list', // 查询城管动态详情

    // 积分相关
    getPersonCredit: CREDIT + 'person-credit', // 得到当前用户的积分
    getCreditRanks: CREDIT + 'credit-ranks', // 排行榜
    getCreditRecords: CREDIT + 'credit-records', // 得到当前用户的积分明细
    getCreditRules: CREDIT + 'credit-rules', // 得到积分规则
    getCreditDisplayClear: CREDIT + 'display-clear', // 积分清空是否显示

    // 奖品相关
    getConvertibleAwards: AWARD + 'awards', // 得到当前可兑换奖品

    // 地点收藏相关
    getPoiCollects: POI_COLLECT + 'poi-collects', // 查询地点收藏

    // 工地相关
    getConsList: CONSTRUCTION + 'consList', // 获取工地列表
    getConsChart: CONSTRUCTION + 'chart', // 获取工地监测数据

    // 政策法规
    getRegulationsList: POLICE + 'list',
    getRegulationsDetails: POLICE + '',

    // 常见问题
    getProblemList: PROBLEM + 'list',
    getProblemDetail: PROBLEM
  },
  post: {
    batchAmap: AMAP_URL + 'batch', // 批量请求高德接口

    // 权限相关
    getWxUserInfo: SECURITY + 'public-logon', // 获取微信用户信息
    check: SECURITY + 'check', // 校验凭证是否有效
    sendMsgCode: SECURITY + 'checkCode', // 发送短信验证码

    // 我要爆料相关
    reportDisclose: DISCLOSE + 'single-disclose', // 新增一条爆料信息

    // 互动咨询相关
    reportConsult: CONSULT + 'consult', // 互动咨询新增

    // 每日一题相关
    saveDailySubjectResult: SUBJECT + 'daily-subject-result', // 保存答题结果

    // 预警预告相关
    addReadRecord: ALARM + 'alarm-read-record', // 添加阅读记录

    // 奖品相关
    exchangeAward: AWARD + 'award-exchange', // 兑换奖品

    // 地点收藏相关
    addPoiCollect: POI_COLLECT + 'poi-collect' // 添加收藏

  },
  put: {
    // 互动咨询相关
    changeReadStat: CONSULT + 'consult-read-stat', // 更新已读状态

    // 个人信息相关
    changeUserPhone: PERSON + 'person-phone' // 更新用户信息 更改手机号
  },
  delete: {
    // 地点收藏相关
    deletePoiCollect: POI_COLLECT + 'poi-collect' // 取消收藏
  }
}
