import Vue from 'vue'
import VueRouter from 'vue-router'
// import { getToken } from '@/common/js/cache'

Vue.use(VueRouter)

// meta: {
//   index: 设置路由层级 方便路由过渡动画的实现  如果不需要则不需要设置
// }

const routes = [
  // 部门动态
  {
    path: '/urbanManageNews',
    name: 'urbanManageNews',
    component: () => import(/* webpackChunkName: "urbanManageNews" */ '../views/urbanManageNews/index.vue'),
    meta: {
      index: 1,
      cache: true,
      title: '部门动态'
    }
  },
  // 常见问题
  {
    path: '/commonProblem',
    name: 'commonProblem',
    component: () => import(/* webpackChunkName: "commonProblem" */ '../views/commonProblem/index.vue'),
    meta: {
      index: 1,
      cache: true,
      title: '常见问题'
    }
  },
  // 常见问题详情
  {
    path: '/commonProblem/:id',
    name: 'problemDetail',
    component: () => import(/* webpackChunkName: "problemDetail" */ '../views/commonProblem/problemDetail.js'),
    meta: {
      cache: true,
      title: '常见问题'
    }
  },

  // 政策法规
  {
    path: '/policyRegulations',
    name: 'policyRegulations',
    component: () => import(/* webpackChunkName: "policyRegulations" */ '../views/policyRegulations/index.vue'),
    meta: {
      index: 1,
      cache: true,
      title: '政策法规'
    }
  },
  {
    path: '/policyRegulations/:id',
    name: 'regulationDetails',
    component: () => import(/* webpackChunkName: "regulationDetails" */ '../views/policyRegulations/regulationDetails.js'),
    meta: {
      cache: true,
      title: '政策法规'
    }
  },

  // 历史爆料
  {
    path: '/reportHistory',
    name: 'reportHistory',
    component: () => import(/* webpackChunkName: "reportHistory" */ '../views/reportHistory/index.vue'),
    meta: {
      index: 1,
      title: '历史爆料',
      cache: true
    }
  },
  // 个人信息
  {
    path: '/baseInfo',
    name: 'baseInfo',
    component: () => import(/* webpackChunkName: "baseInfo" */ '../views/Me/baseInfo.vue'),
    meta: {
      index: 2,
      title: '个人信息'
    }
  },
  // 更改绑定手机
  {
    path: '/baseInfo/changePhone',
    name: 'changePhone',
    component: () => import(/* webpackChunkName: "changePhone" */ '../views/Me/changePhone.vue'),
    meta: {
      index: 3,
      title: '更改绑定手机'
    }
  },
  // 我的积分
  {
    path: '/myPoint',
    name: 'myPoint',
    component: () => import(/* webpackChunkName: "myPoint" */ '../views/myPoint/index.vue'),
    meta: {
      index: 2,
      title: '我的积分'
    }
  },
  // 我的积分 排行榜
  {
    path: '/myPoint/leaderBoard',
    name: 'leaderBoard',
    component: () => import(/* webpackChunkName: "leaderBoard" */ '../views/myPoint/leaderBoard.vue'),
    meta: {
      index: 3,
      title: '排行榜'
    }
  },
  // 我要爆料
  {
    path: '/wantReport',
    name: 'wantReport',
    component: () => import(/* webpackChunkName: "wantReport" */ '../views/wantReport/index.vue'),
    meta: {
      index: 2,
      cache: true,
      title: '我要爆料'
    }
  },
  // 案卷详情
  {
    path: '/reportHistory/:id',
    name: 'caseDetail',
    component: () => import(/* webpackChunkName: "caseDetail" */ '../views/reportHistory/caseDetail.vue'),
    meta: {
      index: 2,
      title: '案卷详情',
      cache: true
    }
  },
  // 城管动态详情
  {
    path: '/urbanManageNews/:id',
    name: 'newsDetail',
    component: () => import(/* webpackChunkName: "newsDetail" */ '../views/urbanManageNews/newsDetail.vue'),
    meta: {
      index: 2,
      title: '部门动态',
      cache: true
    }
  },
  // 预警预告
  {
    path: '/earlyWarning',
    name: 'earlyWarning',
    component: () => import(/* webpackChunkName: "earlyWarning" */ '../views/earlyWarning/index.vue'),
    meta: {
      index: 2,
      title: '预警预告'
    }
  },
  // 预警预告详情
  {
    path: '/earlyWarning/:id',
    name: 'warnDetail',
    component: () => import(/* webpackChunkName: "warnDetail" */ '../views/earlyWarning/warnDetail.vue'),
    meta: {
      index: 3,
      title: '预警预告'
    }
  },
  // 收藏夹
  {
    path: '/favorites/:type',
    name: 'favorites',
    component: () => import(/* webpackChunkName: "favorites" */ '../views/favorites/index.vue'),
    meta: {
      index: 3,
      cache: true,
      title: '收藏夹'
    }
  },
  // 每日一题
  {
    path: '/dailyQuestion',
    name: 'dailyQuestion',
    component: () => import(/* webpackChunkName: "dailyQuestion" */ '../views/dailyQuestion/index.vue'),
    meta: {
      index: 2,
      title: '每日一题'
    }
  },
  // 部门窗口
  {
    path: '/urbanManageWindow',
    name: 'urbanManageWindow',
    component: () => import(/* webpackChunkName: "urbanManageWindow" */ '../views/urbanManageWindow/index.vue'),
    meta: {
      index: 2,
      title: '部门窗口'
    }
  },
  // 互动咨询
  {
    path: '/consultation',
    name: 'consultation',
    component: () => import(/* webpackChunkName: "consultation" */ '../views/consultation/index.vue'),
    meta: {
      index: 2,
      title: '互动咨询'
    }
  },
  // 提问咨询
  {
    path: '/consultation/add',
    name: 'addConsultation',
    component: () => import(/* webpackChunkName: "addConsultation" */ '../views/consultation/addConsultation.vue'),
    meta: {
      index: 3,
      title: '提问咨询'
    }
  },
  // 咨询详情
  {
    path: '/consultation/detail',
    name: 'consultDetail',
    component: () => import(/* webpackChunkName: "consultationDetail" */ '../views/consultation/consultationDetail.vue'),
    props: route => ({ detail: route.params.detail }),
    meta: {
      index: 3,
      title: '咨询详情'
    }
  },

  // 微信公众号地图页面
  // 现场地址
  {
    path: '/selectAddress',
    name: 'selectAddress',
    component: () => import(/* webpackChunkName: "selectAddress" */ '../views/wantReport/selectAddress.vue'),
    meta: {
      index: 3,
      cache: true,
      title: '现场地址'
    }
  },
  // 选择地址
  {
    path: '/around/:type',
    name: 'mapAround',
    component: () => import(/* webpackChunkName: "mapAround" */ '../views/mapAround/index.vue'),
    meta: {
      index: 2,
      cache: true
    }
  },
  // 审批流程查询
  {
    path: '/approveSearch',
    name: 'mapAround',
    component: () => import(/* webpackChunkName: "mapAround" */ '../views/approveSearch/index.vue'),
    meta: {
      index: 2,
      cache: true,
      title: '审批流程查询'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// const whiteList = ['Login', 'Home', 'urbanManageNews', 'newsDetail', 'urbanManageWindow', 'municipalMonitor', 'selectSite', 'mapAround']

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || ''
  next()
  // if (getToken()) {
  //   if (to.name === 'Login') {
  //     next({
  //       path: '/'
  //     })
  //   } else {
  //     next()
  //   }
  // } else {
  //   if (whiteList.indexOf(to.name) !== -1) {
  //     next()
  //   } else {
  //     next('/login')
  //   }
  // }
})

export default router
